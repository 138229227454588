<template>
  <div
    :class="['box', $root.config.theme, $root.config.themeBody]"
    :style="[{ '--left-max-width': leftMaxWidth + 'px' }]"
    v-loading="loading"
    element-loading-text="转存中...请稍等"
    element-loading-background="transparent"
  >
    <div
      :class="[
        'container',
        { 'container-frame': $root.config.frame === 'true' },
        { 'mobile-container': isMobile },
      ]"
    >
      <drag-right
        v-show="leftShow"
        ref="altLeftRef"
        :class="['atl-left dark-7', $root.config.theme, $root.config.themeBody]"
        :min-width="200"
        :max-width="900"
        :init-width="300"
        :show-mode="0"
      >
        <!--      <el-button class="tree-fold" size="small" @click="treeFlex">-->
        <!--        <svg-icon icon-class="new-right"/>-->
        <!--      </el-button>-->
        <div :class="['atl-inner dark-7', $root.config.theme, $root.config.themeBody]">
          <div class="document-container d-column">
            <tips class="d-flex tree-top">
              <svg-icon
                v-if="$root.config.theme === 'light3'"
                icon-class="new-logo-black"
                class-name="icon-logo"
              />
            </tips>
            <div class="document-column">
              <svg-icon icon-class="new-01" class-name="tree-search size-3" />
              <div class="document-column-name">我的模型树</div>
            </div>
            <el-scrollbar ref="scroll" :height="scrollerHeight" class="scroll-box">
              <el-tree
                :icon="angleIcon"
                id="treeBox"
                class="document-list-elm"
                ref="tree"
                node-key="id"
                :key="treeKey"
                :data="treeNodes"
                :empty-text="emptyText"
                :props="defaultProps"
                :draggable="false"
                :expand-on-click-node="false"
                :check-on-click-node="true"
                :highlight-current="true"
                :current-node-key="articleId"
                :default-expanded-keys="defaultExpandKeys"
              >
                <!-- @node-contextmenu="rightClick" -->
                <template #default="{ node, data }">
                  <div
                    class="custom-tree-node-box"
                    @mouseover="onMouseOver(data)"
                    @mouseleave="onMouseLeave(data)"
                  >
                    <div class="custom-tree-node" @click="clickNode(data, node, $event)">
                      <div
                        class="d-flex"
                        :class="{
                          'red-color': data.iconType === 1 && data.isCurrent,
                        }"
                      >
                        <svg-icon
                          v-if="iconMaps[data.iconType]"
                          :icon-class="iconMaps[data.iconType].icon"
                          class-name="size-3 icon-color-white"
                          :class="[
                            {
                              active: data.isCurrent && data.iconType !== 1,
                            },
                            iconMaps[data.iconType].className || '',
                          ]"
                        />
                        <svg-icon
                          v-else-if="!data.parent"
                          icon-class="new-02"
                          class-name="size-3 icon-color-white"
                        />
                        <svg-icon
                          v-else
                          icon-class="new-04"
                          class-name="size-3 icon-color-white"
                        />
                        <span style="margin-left: 8px">{{ data.info.title }}</span>
                      </div>
                    </div>
                    <span>
                      <el-button
                        v-if="data.showMenu"
                        link
                        size="small"
                        @click.stop="() => changeMindMap(node)"
                      >
                        <svg-icon icon-class="new-11" class="d-center size-1" />
                      </el-button>
                    </span>
                  </div>
                </template>
              </el-tree>
            </el-scrollbar>
          </div>
          <div class="tree-bottom-box">
            <div class="tree-bottom-avatar">
              <el-avatar
                shape="square"
                :size="24"
                v-if="$store.state.user && $store.state.user.headImage"
                :src="$store.state.user.headImage"
              />
            </div>
            <div class="tree-bottom-info">
              <div class="tree-bottom-i">
                <div
                  class="tbi-name"
                  v-if="isLogin && $store.state.user && $store.state.user.nickName"
                >
                  {{ $store.state.user.nickName }}
                </div>
                <div v-else class="tbi-name" @click="toLogin">请登录</div>
                <!-- <div class="tbi-id">TreeID：{{ user.accountId }}</div> -->
              </div>
              <div class="tree-bottom-btn">
                <el-button
                  class="button-002"
                  link
                  size="small"
                  @click="outLogin"
                  v-if="isLogin"
                >
                  <el-icon>
                    <SwitchButton />
                  </el-icon>
                </el-button>
                <el-button class="button-002" link size="small" @click="outLogin" v-else>
                  <el-icon>
                    <SwitchButton />
                  </el-icon>
                </el-button>
              </div>
            </div>
          </div>
        </div>
      </drag-right>
      <div class="content article-content pos-relative">
        <div
          class="alt-left-close d-center"
          :class="{
            'alt-closed': altLeft > 0,
            'alt-close-hover': directionStep === 0 || directionStep === 1,
          }"
          @click="onAltLeftClose"
        >
          <div class="alt-close-inner d-cloumn">
            <el-tooltip
              effect="dark"
              :content="(altLeft > 0 ? '打开' : '关闭') + '边栏'"
              placement="right"
            >
              <div>
                <div class="rounded-full"></div>
                <div class="rounded-full"></div>
              </div>
            </el-tooltip>
          </div>
        </div>
        <div
          class="alt-left-close d-center alt-right-close"
          :class="{
            'alt-closed alt-closed-right': altRight > 0,
            'alt-close-hover': directionStepRight === 0 || directionStepRight === 1,
          }"
          @click="onAltRightClose"
        >
          <div class="alt-close-inner d-cloumn">
            <el-tooltip
              effect="dark"
              :content="(altRight > 0 ? '打开' : '关闭') + '文档'"
              placement="left"
            >
              <div>
                <div class="rounded-full"></div>
                <div class="rounded-full"></div>
              </div>
            </el-tooltip>
          </div>
        </div>
        <div class="header d-between">
          <div class="header-left d-center">
            <div class="d-center mind-editor-change">
              <div
                class="btn btn-gray d-center btn-box"
                :class="{ active: showEditor && !showMindMap }"
                @click="onCloseGraph"
              >
                <svg-icon icon-class="file" class-name="svg-small" />
              </div>
              <div
                class="btn btn-gray d-center btn-box"
                :class="{ active: showMindMap }"
                @click="onOpenGraph"
              >
                <svg-icon icon-class="new-11" class-name="svg-small" />
              </div>
            </div>
          </div>
          <div class="header-right d-flex">
            <el-button @click="backBtn" class="top-right-btn" style="width: auto">
              <el-icon>
                <Back />
              </el-icon>
              返回主页
            </el-button>
            <el-button @click="saveBtn" class="top-right-btn" style="width: auto"
              >转存文档
            </el-button>
            <!-- <el-button class="top-right-btn" @click="fullScreen">
              <el-icon>
                <FullScreen />
              </el-icon>
            </el-button> -->
            <!-- <el-divider direction="vertical"></el-divider> -->
            <!-- <div class="right-avatar">
              <el-dropdown
                v-if="isLogin"
                trigger="click"
                :popper-class="[$root.config.theme]"
              >
                <div @click="toLogin">
                  <el-avatar
                    :size="32"
                    fit="fill"
                    src="/pc/avatar.png"
                    icon="UserFilled"
                    style="background: #1380ff; border: 1px solid #c4c4c4"
                  >
                  </el-avatar>
                </div>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item @click="openConfig('config')">
                    <el-icon>
                      <Setting />
                    </el-icon>
                    <span>设置</span>
                  </el-dropdown-item>
                    <el-dropdown-item @click="outLogin">
                      <el-icon>
                        <SwitchButton />
                      </el-icon>
                      <span>退出</span>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
              <div v-else @click="toLogin">
                <el-avatar
                  :size="24"
                  fit="fill"
                  src="/pc/avatar.png"
                  icon="UserFilled"
                  style="background: #1380ff"
                >
                </el-avatar>
              </div>
            </div> -->

            <!-- <div class="column-line" v-if="!showEditor && showMindMap"></div>
            <el-tooltip
              v-if="!showEditor"
              effect="dark"
              content="文档模式"
              placement="bottom"
            >
              <div
                class="btn btn-gray d-center btn-box open-doc"
                @click="onOpenEditor"
              >
                <svg-icon icon-class="slider-close" class="size-2"></svg-icon>
              </div>
            </el-tooltip> -->
          </div>
        </div>
        <div class="column flex-1" v-if="showMindMap">
          <div class="mind-box" :style="'height:' + editorHeight + 'px'">
            <div class="pos-absolute mind-loading d-column" v-if="loadingMap">
              <div class="ui-loading middle"></div>
              <span>加载中...</span>
            </div>
            <mind-graph
              v-if="mindItem"
              ref="mindmap"
              mode="default"
              theme="white"
              :model-value="mindItem"
              :mindmap="true"
              :resize-center="false"
              :sharp-corner="true"
              :hide-edge="false"
              :default-edge-style="this.$root.mindEdgeStyle"
              :default-node-style="this.$root.mindNodeStyle"
              @change="onMindMapChange"
            />
          </div>
        </div>
        <div class="column flex-1" v-else-if="showEditor" style="display: flex">
          <mxs-share
            v-if="isTest"
            :shareId="articleId"
            :articleId="activeItem ? activeItem.id : ''"
            :title="activeItem?.name"
          >
          </mxs-share>
          <div class="vditor-dom" v-else>
            <div class="vditor-box">
              <div class="title-box" style="padding-top: 20px">
                {{ articleDetail.title }}
              </div>
              <el-scrollbar :max-height="editorHeight" always>
                <div class="fr-wrapper">
                  <div
                    ref="content"
                    class="fr-element fr-view"
                    v-html="articleDetail.content"
                  ></div>
                </div>
              </el-scrollbar>
            </div>
          </div>
        </div>
      </div>
      <drag-view
        class="document-drag-right"
        :init-width="editorWidth"
        :min-width="0"
        direction="left"
        :no-fixed="true"
        v-model:view-width="editorWidth"
        v-if="showEditor && activeItem && showMindMap"
      >
        <mxs-share
          v-if="isTest"
          :shareId="articleId"
          :articleId="activeItem ? activeItem.id : ''"
          :title="activeItem?.name"
        >
        </mxs-share>
        <div class="vditor-dom right-bg-color" v-else>
          <div class="vditor-box">
            <div class="title-box d-center" style="padding-top: 20px">
              <span class="flex-1">
                {{ articleDetail.title }}
              </span>
              <el-button class="right-close-btn d-center" @click="onCloseEditor">
                <svg-icon icon-class="new-65" />
              </el-button>
            </div>
            <el-scrollbar :max-height="editorHeight" always>
              <div class="fr-wrapper">
                <div
                  ref="content"
                  class="fr-element fr-view"
                  v-html="articleDetail.content"
                ></div>
              </div>
            </el-scrollbar>
          </div>
        </div>
        <link-tree v-if="false" />
      </drag-view>
    </div>
  </div>
  <tree-dialog
    :is-local="false"
    :need-load-data="true"
    :group-enabled="false"
    :save-cur-group="true"
    :init-list="[
      {
        id: 'root',
        expand: true,
        hasChildren: true,
        title: '我的模型树',
        children: $root.cloudTreeList,
      },
    ]"
    :theme="$root.config.theme"
    :filter-item="{}"
    v-model:dialog-type="treeDialogType"
    @submit="onSubmit"
  />
  <el-dialog
    v-model="showLoading"
    custom-class="loading-class"
    align-center
    width="300px"
    :show-close="false"
    :close-on-click-modal="false"
  >
    <div class="show-loading">数据加载中</div>
  </el-dialog>
</template>
<script>
import MindGraph from "@/views/pc/article/mind-map/MindGraph";
import screenfull from "screenfull";
import { iconMaps } from "../../../../../utils/TreeConstants";
import AngleIcon from "./AngleIcon";
import TreeDialog from "../../tree/TreeDialog";
import DragView from "../../widget/DragView";
import MxsShare from "./MxsShare.vue";
import { MindmapEvent } from "../../../../../components/antv-mindmap/mxs-mindmap.es";
import DragRight from "../../document/DragRight.vue";
import Tips from "../../widget/Tips";
const editorHeight = window.innerHeight - 65;
export default {
  components: {
    MxsShare,
    DragView,
    TreeDialog,
    MindGraph,
    DragRight,
    Tips,
  },
  data() {
    return {
      editorWidth: "400px",
      // isTest: localStorage.getItem("__test") === "1",
      isTest: true,
      showEditor: true,
      showMindMap: true,
      activeItem: null,
      mindItem: null,
      preShowEditor: true,

      defaultExpandKeys: [],
      treeDialogType: 0,
      angleIcon: AngleIcon,
      iconMaps,
      loadingMap: false,
      articleId: "",
      pageIndex: 0,
      historyList: null,
      logoUrl: "/pc/logo-dark.png",
      theme: "dark4",
      leftMaxWidth: 1000,
      listHeight: 0,
      scrollerHeight: 0,
      treeKey: 0,
      isLazy: true,
      emptyText: "加载中",
      defaultProps: { label: "name", isLeaf: "hasChildren" },
      treeNodes: [],
      isLogin: "false",
      configVisible: false,
      configActive: "config",
      timeOutClick: null,
      articleDetail: [],
      editorHeight: editorHeight,
      saveDialog: {
        visible: false,
        title: "",
        value: "",
        shareId: "",
        catalogId: "",
      },
      timeOutTips: null,
      midType: "editor",
      mindKey: 0,
      mindCollapse: 5,
      themeList: {
        branchColor: "#404040",
        themeColor: "#404040",
        rootFontColor: "#FFF",
        subThemeColor: "#F5F5F5",
        subFontColor: "#666666",
        leafThemeColor: "#FFFFFF",
        leafFontColor: "#666666",
      },
      hotKeyList: [
        { name: "add", enabled: false },
        { name: "add-sibling", enabled: false },
        { name: "copy", enabled: false },
        { name: "cut", enabled: false },
        { name: "paste", enabled: false },
        { name: "paste", enabled: false },
      ],
      loading: false,
      showLoading: false,
      leftShow: true,
      // loadingStatus: 0
    };
  },
  inject: ["reload"],
  created() {
    // 判断路由是否包含节点ID
    if (this.$route.params.articleId) {
      this.articleId = this.$route.params.articleId;
    }
    this.isLogin = localStorage.getItem("isLogin");
    if (this.isLogin === "true") {
      // this.changeConfig();
    }
    const p = localStorage.getItem("__test");
    if (p !== "1") {
      localStorage.setItem("__test", "1");
      window.location.reload();
      return;
    }
    // document.body.setAttribute(
    //   "class",
    //   "theme-" + (this.$root.config.theme || "dark4")
    // );
  },
  watch: {
    // "$root.config": {
    //   handler(val, old) {
    //     if (val !== old) {
    //       this.changeConfig();
    //     }
    //   },
    // },
    activeItem(val, old) {
      if (val !== old && val) {
        this.getArticleDetail(this.articleId, val.id);
      }
    },
  },
  mounted() {
    // this.changeConfig();
    this.listHeight = window.innerHeight - 102 + "px";
    this.scrollerHeight = window.innerHeight - 150 + "px";
    this.getTreeDatas();
  },
  methods: {
    onMindMapChange({ type, options }) {
      switch (type) {
        case MindmapEvent.nodeSelect:
          {
            const model = options.node.getModel();
            this.$refs.tree.setCurrentKey(model.id);
            const node = this.$refs.tree?.getNode(model.id);
            if (node) {
              this.activeItem = node.data;
            }
          }
          break;
        case "openEditor":
          {
            const model = options.model;
            this.$refs.tree.setCurrentKey(model.id);
            const node = this.$refs.tree?.getNode(model.id);
            if (node) {
              const mapNode = this.$refs.mindmap?.graph.findById(model.id);
              mapNode.getModel().noAdd = true;
              this.$refs.mindmap?.graph.editSelectedNode(mapNode, true, false);
              this.activeItem = node.data;
              this.showEditor = true;
            }
          }
          break;
      }
    },
    onCloseGraph() {
      this.showMindMap = false;
      if (!this.showEditor) {
        this.showEditor = true;
      }
    },
    onOpenGraph() {
      this.showMindMap = true;
      this.showEditor = this.preShowEditor;
    },
    onCloseEditor() {
      this.showEditor = false;
      this.preShowEditor = false;
    },
    onOpenEditor() {
      this.showEditor = true;
      this.preShowEditor = true;
    },
    onSubmit(item, group) {
      if (this.loading) return;
      this.loading = true;
      this.saveTimer = setTimeout(() => {
        this.loading = false;
        this.$root.tips(
          "info",
          "节点内容较多，正在后台拼命复制，复制完成会有系统提醒，您可以继续使用模型树！"
        );
      }, 1000);
      const curActiveItemId = this.$root.activeItem.id;
      this.$api.share
        .save(this.articleId, !item || item.id === "root" ? "" : item.id)
        .then((res) => {
          if (res.data.isSuccess) {
            this.$root
              .clearList()
              .then(() => {
                return this.$root.loadTreeList();
              })
              .then(() => {
                this.loading = false;
                this.$root.curGroup = group;
                this.tips("success", "转存成功");
                //此处最好优化，返回转存后的tree list 或者tree最外层id，去获取转存后列表即可
                setTimeout(() => {
                  this.$router.push(
                    `/article${curActiveItemId ? "/" + curActiveItemId : ""}`
                  );
                }, 500);
              });
          } else {
            clearTimeout(this.saveTimer);
            this.tips("error", res.data.message);
          }
        })
        .catch((err) => {
          clearTimeout(this.saveTimer);
          console.log(err);
          this.loading = false;
          this.tips("error", err.message || "转存失败，请稍后重试！");
        });
    },
    changeConfig() {
      if (this.$root.config.theme) {
        if (this.$root.config.theme.includes("dark")) {
          // 暗色只有dark3 且是默认
          this.$root.config.theme = "dark4";
          this.logoUrl = "/pc/logo-dark.png";
          this.themeList = {
            // branchColor: "#109adc",
            branchColor: "#5a6ef0",
            themeColor: "#5a6ef0",
            rootFontColor: "#EBEBEB",
            subThemeColor: "#1A1A1A",
            subFontColor: "#EBEBEB",
            leafThemeColor: "#1A1A1A",
            leafFontColor: "#EBEBEB",
          };
        } else {
          this.logoUrl = "/pc/logo-default.png";
        }
      }
    },
    refresh() {
      this.reload();
    },
    // 获取文档树(从下往上展开)
    async getTreeDatas() {
      // this.loadingStatus = 0;
      // const res = await this.$api.share.getShareInfo(this.articleId);
      // if (res.data.isSuccess) {
      //   this.shareInfo = res.data.data;
      //   this.loadingStatus = 1;
      // } else {
      //   this.loadingStatus = 2;
      //   return;
      // }
      const data = await this.getArticleTree(this.articleId);
      if (data) {
        this.defaultExpandKeys = [data.id];
        this.treeNodes = [data];
        this.activeItem = data;
        this.mindItem = this.activeItem;
      }
      if (this.treeNodes.length > 0) {
        this.$nextTick(async () => {
          if (await this.$refs.tree) {
            await this.$refs.tree.setCurrentKey(this.treeNodes[0].id);
          }
          // this.getArticleDetail(this.articleId, this.treeNodes[0].id);
        });
        this.$nextTick(async () => {
          if (await this.$refs.mindmap) {
            setTimeout(async () => {
              await this.$refs.mindmap.graph.editFitCenter();
            }, 100);
          }
        });
      }
    },

    // 获取文档树(从下往上展开)
    async getArticleTree(articleId) {
      const res = await this.$api.share.articleTree(articleId);
      if (res.data.data && res.data.data.info) {
        return this.readNodes(res.data.data);
      }
      return;
    },
    readNodes(data, parentId = null, level = 0) {
      data.id = data.info.id;
      data.name = data.info.title;
      data.iconType = data.info.iconType;
      data.parentId = parentId;
      // data.lineColor = "#1380FF";
      data.hasChildren = true;
      data.level = level;
      if (level >= this.mindCollapse) {
        data.collapse = true;
      } else {
        data.collapse = false;
      }
      if (data.children && data.children.length > 0) {
        data.hasChildren = false;
        level += 1;
        for (let i = 0; i < data.children.length; i++) {
          this.readNodes(data.children[i], data.info.id, level);
        }
      }
      return data;
    },
    fullScreen() {
      screenfull.toggle();
    },
    // 登录
    toLogin() {
      if (this.$store.state.isLogin) {
        // this.$message.success("您已登录");
      } else {
        // localStorage.setItem("prePath", this.$route.path);
        this.$router.push("/login");
      }
    },
    outLogin() {
      // this.$store.commit("delToken");
      // this.isLogin = false;
      // this.toLogin();
      localStorage.setItem("prePath", this.$route.path);
      this.$root.logout();
    },
    // 打开设置页面
    openConfig(configActive = "bindAccount") {
      this.$root.configActive = configActive;
      this.$root.configVisible = true;
    },
    closeConfig() {
      this.refresh();
    },
    async backBtn() {
      if (this.$store.state.isLogin) {
        this.showLoading = true;
        this.$root.loadTreeList().then(() => {
          setTimeout(() => {
            this.showLoading = false;
            this.$router.push(
              `/article${this.$root.activeItem ? "/" + this.$root.activeItem.id : ""}`
            );
          }, 200);
        });
      } else {
        this.toLogin();
      }
    },
    getArticleDetail(shareId, articleId) {
      if (this.isTest) return;
      this.$api.share.articleDetail(shareId, articleId).then((res) => {
        if (res.data && res.data.data) {
          this.articleDetail = res.data.data;
          this.$nextTick(() => {
            this.$api.upload.loadImages(this.$refs.content);
          });
        }
      });
    },
    // 单击树节点标题打开文档详情
    clickNode(data, node, event) {
      this.activeItem = data;
      // this.midType = "editor";
      // this.showEditor = true;
      if (this.showMindMap) {
        if (this.timeOutClick) {
          window.clearTimeout(this.timeOutClick);
        }
        const mapNode = this.$refs.mindmap?.graph.findById(data.id);
        if (mapNode) {
          mapNode.getModel().noAdd = true;
          this.$refs.mindmap?.graph.editSelectedNode(mapNode, true, false);
          this.timeOutClick = window.setTimeout(() => {
            node.expanded = true;
            // this.getArticleDetail(this.articleId, data.id);
          }, 300);
        }
      }
    },
    saveBtn() {
      if (this.$store.state.isLogin) {
        // localStorage.removeItem("preRoute");
        // this.saveDialog.title = "转存模型到";
        // this.saveDialog.shareId = this.articleId;
        this.$api.share
          .enableSaveShare(this.articleId)
          .then((res) => {
            if (res.data.isSuccess) {
              this.treeDialogType = 4;
            } else {
              this.tips("error", res.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
          });
        // this.saveDialog.visible = true;
      } else {
        // console.log(window.location);
        setTimeout(() => {
          this.toLogin();
        }, 100);
      }
    },

    // 提示信息
    tips(type, message) {
      if (this.timeOutTips) {
        window.clearTimeout(this.timeOutTips);
      }
      this.timeOutTips = window.setTimeout(() => {
        this.$message({
          message: message,
          type: type,
          grouping: true,
        });
      }, 500);
    },
    // 获取子目录
    async getChildrenList(articleId = "") {
      var data = await this.$api.article.childrenList(articleId);
      if (data.length > 0) {
        return data;
      } else {
        return [];
      }
    },

    // 额外模型树数据处理
    dataHandling(data) {
      //更换键名
      data = this.changekeyName(data);
      if (this.saveDialog.visible == true && this.saveDialog.moveId != "") {
        data = data.filter((item) => item.id != this.saveDialog.moveId);
      }
      for (let i = 0; i < data.length; i++) {
        // 修改思维导图线条颜色
        // data[i].lineColor = "#1380FF";
        // 树节点判断是否有子节点
        data[i].hasChildren = !data[i].hasChildren;
      }
      return data;
    },
    //更换键名 data为数组，keyName为修改前的键名，name为修改后的键名
    changekeyName(data) {
      // JSON.parse(JSON.stringify(data).replace(/keyName/g, 'name'))
      var newData = JSON.parse(JSON.stringify(data).replace(/title/g, "name"));
      return newData;
    },
    moveTreeClick(data, node, event) {
      if (node.expanded == false) {
        node.expand();
      }
      this.saveDialog.catalogId = data.id;
    },
    onMouseOver(data) {
      data.showMenu = true;
    },
    onMouseLeave(data) {
      if (data.visible) {
        data.showMenu = true;
      } else {
        data.showMenu = false;
      }
    },
    // 打开总思维导图
    openMindMap() {
      this.$api.article.fullArticleTree().then((res) => {
        var data = res.data;
        data.info = {
          id: "000",
          title: "我的模型树",
          parentId: null,
        };
        this.activeItem = data;
        this.showMindMap = true;
      });
    },

    // 打开思维导图
    changeMindMap(node) {
      if (node.id === "total") {
        this.openMindMap();
      } else {
        this.loadingMap = true;
        this.mindItem = node.data;
        this.showMindMap = true;
        this.loadingMap = false;
      }
    },
    // 思维导图数据转化
    setMindData(data, parentId = null, level = 0) {
      data.id = data.info.id;
      data.title = data.info.title;
      data.parentId = parentId;
      // data.lineColor = "#1380FF";
      data.hasChildren = true;
      data.level = level;
      if (data.children && data.children.length > 0) {
        data.hasChildren = false;
        level += 1;
        for (let i = 0; i < data.children.length; i++) {
          this.setMindData(data.children[i], data.info.id, level);
        }
      }
      return data;
    },
    onAltLeftClose() {
      if (this.leftShow) {
        this.leftShow = false;
        this.altLeft = 1;
      } else {
        this.leftShow = true;
        this.altLeft = 0;
      }
    },
    onAltRightClose() {
      if (this.showEditor) {
        this.showEditor = false;
        this.altRight = 1;
      } else {
        this.showEditor = true;
        this.altRight = 0;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
html,
body,
div.box {
  height: 100vh;
  width: 100%;
  overflow: hidden;
}
.open-doc {
  color: #bfbfbf;
}
.right-close-btn {
  background-color: transparent;
  border-color: transparent;
  width: 32px;
  height: 32px;
  padding: 0;
  margin-right: 10px;
  svg {
    width: 28px;
    height: 28px;
  }
}

div {
  box-sizing: border-box;
}

div.box {
  display: flex;
}

div.left {
  position: relative;
  height: 100%;
  //outline: 1px solid #fff;
  outline: none;
}

div.content {
  flex: 1;
  height: 100%;
  padding: 0 0 0 2px;
  background: var(--color-body);
  display: flex;
  flex-direction: column;
}

/* 侧边栏文本样式 */
div.text {
  width: 100%;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  // background: #ffffff;
  // border-right: 1px solid #e9e9e9;
  background-color: var(--color-atl-left);
}

.mind-box {
  position: relative;
  width: 100%;
}

/*拖动按钮样式*/
/*拖动bar盒时，父级盒side会被bar盒撑开，又因为text盒的宽高都设为100%，所以改变bar盒就是在改变text盒*/
div.bar {
  width: 300px;
  height: 100vh;
  margin-right: -2px;
  /* 因为bar盒子和text盒子是重合的所以必须设置一个负值才能看到我们的拖动按钮*/
  overflow: scroll;
  resize: horizontal;
  /*水平拖放*/
  min-width: 200px;
  max-width: 600px;
  cursor: e-resize;
}

.bar-btn {
  cursor: e-resize;
}

.bar-border {
  position: absolute;
  width: 5px;
  top: 0;
  right: 0;
  height: 100%;
  // border-right: 1px solid #e9e9e9;
  cursor: e-resize;
  z-index: 2;
}

/*因为拖动按钮属于滚动条系列，所以可通过改变滚动来间接改变拖动按钮的样式*/
div.bar::-webkit-scrollbar {
  width: 10px;
  height: 9999px;
}

.flex-s {
  flex-shrink: 0;
}

.flex-g {
  flex-grow: 1;
  min-width: 20%;
}

.pointer {
  cursor: pointer;
}

.container {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  // background-color: #ffffff;

  .left {
    flex-shrink: 0;
    width: 260px;
    max-width: 260px;
    background: transparent;
    mix-blend-mode: normal;
  }

  .right {
    min-width: 50%;
    flex-grow: 1;
    height: 100%;
  }
}

.recycle-bin {
  width: 100%;
  box-sizing: border-box;
  background: #eeeef3;
}

.right-header {
  height: 64px;
}

.left-footer {
  padding: 0;
}

.user-container {
  display: flex;
  align-items: center;
  // justify-content: center;
  height: 70px;

  .user-container-head {
    @extend .flex-s;
    width: 123px;
    height: 40px;
  }

  .user-container-nick {
    @extend .flex-g;
    padding: 0 10px;
    color: #109adc;
    font-size: 20px;
  }

  .user-container-msgBtn {
    @extend .pointer;
    width: 24px;
    height: 24px;
    text-align: center;
    line-height: 24px;
    border-radius: 6px;

    &:hover {
      background-color: rgba(#000, 0.04);
    }
  }
}

.right-main {
  padding: 0;
}

.search-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px 5px 15px;
  border-bottom: 1px solid #eee;

  .search-bar-input {
    @extend .flex-g;
    height: 32px;
    font-size: 14px;
    padding: 10px;
    background-color: rgb(233, 233, 235);
    border: none;
    border-radius: 6px;
    box-sizing: border-box;
  }

  .search-bar-btn {
    text-align: center;
    line-height: 40px;
  }
}

.item-bar {
  display: flex;
  align-items: center;
  // justify-content: center;
  padding: 0 15px 0px 15px;
  border-bottom: 1px solid #eee;

  .item-bar-btn {
    position: relative;
    display: inline-flex;
    width: auto;
    min-width: 0px;
    height: 26px;
    box-sizing: border-box;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 0px 6px;
    border: none;
    background-color: rgb(244, 244, 245);
    border-radius: 6px;
    color: rgb(117, 117, 125);
    cursor: pointer;
    font-size: 14px;
    outline: none;
    text-align: center;
    text-decoration: none;
    transition: background-color 0.2s ease 0s, color 0.2s ease 0s, box-shadow 0.2s ease 0s,
      border 0.2s ease 0s;
    white-space: nowrap;
    margin-right: 10px;
  }
}

.custom-tree-node-svg {
  vertical-align: middle;
  margin-bottom: 3px;
  margin-right: 5px;
}

.document-list {
  padding: 5px 0;
  display: block;
}

.document-column {
  @extend .pointer;
  display: flex;
  align-items: center;
  margin: 0 20px 5px;
  height: 40px;
  // background: #f4f4f4;
  mix-blend-mode: normal;
  border-radius: 4px;

  &:nth-last-child(1) {
    margin-bottom: 0;
  }

  &:hover,
  &[active] {
    // background: #f4f4f4;
    mix-blend-mode: normal;
    border-radius: 4px;
  }

  .document-column-icon {
    @extend .flex-s;
    margin-left: 10px;
    width: 16px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    color: #1380ff;
  }

  .document-column-name {
    @extend .flex-g;
    font-size: 14px;
    padding: 0 5px;
    // color: #4d627a;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .document-column-methods {
    @extend .flex-s;
    margin-right: 12px;
    margin-top: 4px;

    .document-column-methods-item {
      width: 24px;
      height: 24px;
      line-height: 24px;
      border-radius: 6px;
      text-align: center;

      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
  }
}

.document-list-elm {
  background-color: transparent;
  font-size: 9px;
  // color: #4d627a;
  margin: 0 20px;
  padding-bottom: 40px;

  .custom-tree-node-box {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
    height: 36px;

    .custom-tree-node {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      padding-right: 8px;
      height: 36px;
    }

    .custom-tree-node-methods {
      display: none;
      line-height: 36px;
    }

    &:hover {
      // .custom-tree-node {
      //   color: #1380ff;
      // }
      // background: #f2f2f2;
      border-radius: 4px;

      .custom-tree-node-methods {
        display: block;
      }
    }
  }
}

:deep .el-tree__drop-indicator {
  position: absolute;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #1380ff !important;
}

:deep .el-tree-node.is-drop-inner > .el-tree-node__content {
  box-sizing: border-box;
  border: 1px dashed #1380ff;
  border-radius: 4px;
}

:deep .el-tree-node__content > .el-tree-node__expand-icon {
  margin: 0;
  padding: 6px;
  -webkit-box-sizing: initial;
  -moz-box-sizing: initial;
  box-sizing: initial;
}

.document-elm-menu {
  > *:nth-child(2) {
    display: inline-block;
    margin-left: 10px;
  }
}

.document-btn {
  font-size: 12px;
  padding-left: 4px;
  margin-left: 8px !important;
  padding-top: 6px;
}

.data-input {
  width: 80%;
}

.el-input__inner::placeholder {
  color: #333333;
}

.el-input__inner::-webkit-input-placeholder {
  color: #333333;
  text-align: center;
}

.el-input__inner:-moz-placeholder {
  color: #333333;
}

.el-icon-plus-blue {
  color: #1380ff;
}

:deep .el-breadcrumb__separator {
  margin: -1px 0 0 5px;
  font-weight: 500;
  font-size: 12px;
  color: #6c6c6c;
  display: inline-block;
  vertical-align: top;
}

.button-group {
  position: absolute;
  bottom: 0;
  width: 100%;
  border-bottom: 0px;
}

.right-breadcrumb {
  font-size: 12px;
  color: #1f2329;
  margin-left: 20px;
  margin-top: 24px;
  max-width: 500px;
  line-height: 16px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.right-refresh {
  // color: #666666;
  font-size: 10px;
  margin-left: 20px;
  margin-top: 5px;
}

.refreshactive {
  color: #1380ff;
}

:deep .el-divider--vertical {
  margin: 0 8px 0 8px !important;
}

#mindmap {
  height: 100%;
}

.active {
  color: #1380ff;
}

.icon-color {
  color: #666666;
}

.mind-map-box {
  float: left;
  width: 100%;
}

.smaller {
  // width: calc(70% - 10px) !important;
  overflow: hidden;
}

.el-dropdown-menu__item--divided:before {
  margin: 0 !important;
}

.el-dropdown-menu__item--divided {
  margin: 0 !important;
}

.el-dropdown-menu {
  padding: 5px !important;
}

.over-content {
  max-width: 80px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.scroll-box {
  transform-origin: left bottom;
  transform: translate(0px, 0px) scale(1);
  transition: all 0.5s ease 0.5s;
}

.dropdown {
  vertical-align: middle;
}

.move-dialog {
  :deep .el-dialog {
    border-radius: 12px;

    .el-dialog__body {
      padding: 0 20px;
    }
  }
}

.offer-dialog {
  :deep .el-dialog {
    border-radius: 12px;

    .el-dialog__body {
      padding: 0 20px 20px 20px;
    }
  }
}

.red-color {
  color: #de4e4e !important;
}

:deep .fr-box.fr-basic .fr-element {
  padding: 0;
  color: #000;
}

.resize-bar {
  width: var(--left-max-width);
  max-width: var(--left-max-width);
}

.resize-bar::-webkit-scrollbar {
  width: var(--left-max-width);
  height: inherit;
}

.help-box {
  position: fixed;
  right: 20px;
  bottom: 40px;
  z-index: 2000;
}

:deep .el-message .el-message__badge {
  display: none !important;
}

:deep .el-badge.el-message__badge {
  display: none !important;
}

.vditor-box {
  flex: 1;
  width: 100%;
  position: relative;
  margin: 0 auto;
  max-width: 750px;
  // border: 2px solid #e8f3ff;
  // border: 1px solid #cccccc;
  border-radius: 10px;
  height: 100%;
  overflow: hidden;
}

.fr-element p {
  margin-block-start: 0;
  text-align: justify;
  font-size: 15px;
}

.fr-element p span {
  border-radius: 4px;
}

:deep .fr-element img {
  max-width: 95% !important;
}

.title-box {
  font-size: 26px;
}

[class*=" dark"] {
  .title-box {
    color: #ebebeb;
  }
}

.vditor-dom {
  padding: 0 20px;

  .dox-viewer {
    background-color: transparent !important;
  }
}

.dark7
  :deep
  .el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  background: var(--fill-active);
}

:deep .el-tree-node__content:hover {
  background-color: var(--fill-active);
}

.icon-logo {
  width: 120px !important;
  height: 40px !important;
}

.el-button.is-link {
  color: #fff;
}

:deep .el-tree-node__expand-icon.is-leaf i {
  display: none;
}

.box .content .header {
  background: var(--color-body);
  border-bottom: 1px solid var(--color-atl-left);
}

.show-loading {
  text-align: center;
  padding: 30px 0 60px;
}

.dark-7 .fr-element {
  color: #ebebeb;
}
.dark-7 .container-frame .document-container {
  height: calc(100% - 66px);
}
</style>
